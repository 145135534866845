.buch
  padding 1rem 0
  border-bottom 1px solid #ccc
  &:last-child
    border-bottom transparent

  @media $md_and_up
    display flex
    flex-direction row
    flex-wrap: wrap;
  &__cover
    max-width: 200px
    margin 0 auto
    @media $md_and_up
      margin 0
      flex-basis: 30%
    img
      width 100%
  &__content
    margin 1rem
  &__description
    text-align: left
    p:first-child
      margin-top 0
    @media $md_and_up
      flex-basis 70%
      padding-left 1rem
  &__title
    @media $md_and_up
      flex-basis 100%

    .wrapper h4&
      margin-top 0
      @media $md_and_up
        text-align: left;
  &__action-container

    display flex
    flex-wrap: wrap;
    @media $md_and_up
      flex-basis: 100%
      padding-top 1rem
  &__action
    border $color-buttonrahmen 1px solid
    padding 8px
    margin 0.5rem
    border-radius: 3px
    text-align: center;
    display inline-block
    width 100%
    background lightness($color-blue-alt1,66%)
    @media $md_and_up
      width 45%

    &:hover
      background lightness($color-blue-alt1,46%)


    &--buch7
      color #fefefe
      border transparent
      background $color-green-alt1
      &:hover
        background lightness($color-green-alt1,46%)
