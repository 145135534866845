.navigation
  font-family: Montserrat, 'Helvetica', 'Arial', sans-serif
  font-size 0.8rem
  text-align: center;
  &__list
    width 100%
    padding 0
  li
    list-style-type none
    display inline-block
    position: relative;

    &:before
    &:last-child:after
      content ''
      position: relative;
      top -2px
      width 5px
      height 5px
      border-radius 100%
      background black
      display inline-block
      margin-left 10px
      margin-right 10px

.navigation-secondary
  &
  &[class*=pure-u]
    display flex
    margin 0 auto
    padding 2rem 0
    flex-direction column
    align-self stretch
    text-align center
    @media $md_and_up
      flex-direction row
  &__wrapper
    background $color-alt3
  &__item
    flex 1
    padding .5rem 0
    @media $md_and_up
      padding 0
  &__link
    padding .5rem 1rem
    white-space: nowrap;
    display inline-flex
    flex-basis auto
    text-align: center;
    border transparent
    border-radius: 8px
    background $color-green-alt1
    color #fefefe
    &:hover
      background lightness($color-green-alt1,46%)
    &--light
      color black
      background: $color-secondary
      &:hover
        background lightness($color-secondary,46%)
        color #fefefe
