html
body
body .pure-g [class*=pure-u]
  color #000000de
  font-size 20px
  font-family Montserrat, 'Helvetica', 'Arial', sans-serif
  max-width 98vw
  & *
    box-sizing border-box
  @media $md_and_up
    font-size 18px

a
  color #000000de
  text-decoration none
.wrapper
  [class*=pure-u]
    margin 0 auto
    padding 2rem
  &--color-1
    background $color-secondary
  &--color-2
    background $color-alt4
  &--color-alt3
    background $color-blue-alt1
p a
  text-decoration: underline;
.footprint p
  text-align: center;
  font-size 0.8rem

.wrapper textarea
  width 100%
  max-width: 100%
  min-height: 4rem
  font-family: courier

.img-sidebar
  &.right
    @media $md_and_up
      max-width: 33%
      float right
      //margin-right: -240px
  &.left
    @media $md_and_up
      max-width: 33%
      float left
      padding-right 18px
      //margin-left: -280px
  img
    border-radius: 100%
    max-width: 100%
    padding-left 1rem
    margin 0 auto
    float none
    display block

.wrapper .img.center img
  text-align: center
  max-width 33%
  margin 0 auto
  display block
  border-radius: 100%


.unterstuetzer
  margin 0 auto
  text-align: center
  &-item 
    img 
      max-width: 33%