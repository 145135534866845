
.wrapper h1
.wrapper h2
.wrapper h3
.wrapper h4
.wrapper h5
  font-family Montserrat, 'Helvetica', 'Arial', sans-serif
  text-align: center;
  font-size 1.4rem
  &.headline
    font-size 2.5rem
