.header
  &-content
    text-align: left;
    margin 0 auto
    img
      max-width 100%
      width 100vw
  &__container 
    position relative

.video
  &__container
    position relative
    cursor pointer
    text-align: center
    img 
      max-width: 100%

  &__video
    margin 0 auto
    text-align: center
    position: absolute
    top 0
    right 0
    left 0

    display none
    &--show
      display block
  &__icon
    position absolute
    padding-top 4px

    transform translate(-50%, -50%)
    left 50%
    top 50%

    font-size 35px
    color #333
    display block
    text-align: center
    .icon
      width 50px
      height 50px
      border 2px solid white
      //color #999
      border-radius: 100%
      padding-left 6px
      
      background #ffffffaa
    .text
      background #ffffffaa
      padding 5px
      margin-top 15px
      display block
      font-size 20px
 
      
    


    &--really
      color black
      z-index 2

@keyframes fade-out {
    from {opacity: 1}
    to {opacity: 0}
}


