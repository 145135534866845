.footer
  padding 5rem
  display flex
  align-items stretch
  background-color: $color-seitenrahmenhintergrund
  flex-direction column
  @media $lg_and_up

    flex-direction row
  a
    padding .75rem
    flex-grow: 1
    text-align center
